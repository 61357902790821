// BallToggle.scss
.ballToggleContainer {
    width: 100%;
    padding: 10px 0 0 0;
    user-select: none;
  }
  
  .gauge {
    position: relative;
    height: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    margin-bottom: 20px;

    .min-value, .max-value, .optimal-min-value, .optimal-max-value {
      color: #212121;
      font-size: 12px;
      position: absolute;
      top: 22px;
    }
    .min-value {
      left: 0;
    }
    .max-value {
      right: 0;
    }

  }
  
  .gauge__range--optimal {
    position: absolute;
    background-color: #00C853;
    height: 100%;
    border-radius: 10px;

    .optimal-min-value, .optimal-max-value {
      color: #00C853;
      z-index: 1;
    }

    .optimal-min-value {
      left: 0;
    }
    .optimal-max-value {
      right: 0;
    }
  }
  
  .gauge__ball {
    position: absolute;
    width: 25px;
    height: 25px;
    background: url('../../media/Swings/golf-ball.svg') no-repeat center center; // Use the SVG image
    border: none;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }

  