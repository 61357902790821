.swing-area-pane {
    position: fixed;
    top: 0;
    max-width: 850px;
    right: -100%; // Start off-screen
    width: 100%; // Take half of the screen width
    height: 100%; // Full height of the viewport
    background: white;
    transition: right 0.5s ease; // Smooth sliding transition
    overflow-y: auto; // Enable vertical scrolling
    box-sizing: border-box; // Include padding and border in the element's total width and height
    z-index: 1300; // Ensure it is above most content (MUI Dialog z-index level)
    
    &.open {
        right: 0; // Slide in to view
    }
}

@media (max-width: 768px) {
    .swing-area-pane {
        width: 100%; // Full width for smaller screens
    }
}

.dialog-title {
    color: #212121;
}

.dialog-description {
    font-size: 1em;
}

.video-title {
    color: #315a98;
    margin-top: -4px;
}
.videoDescription {
    font-size: 1.4em;
}

.related_video_description {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    padding-bottom: 10px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 20px;
    border-bottom: 1px solid #e9e9e9;
}


.MeasurementDialogImage {
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
}

.dialogButton {
    background-color: #4782da;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 20px;
    border-radius: 4px;
    margin: 0 10px;
    &:hover {
        background-color: #315a98;
    }
}

.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); // Black background with opacity for the dark effect
    z-index: 1200; // Ensure it's below the dialog but above other content
    display: none; // Initially hidden
  }
  
  .dialog-overlay.open {
    display: block; // Display the overlay when the dialog is open
  }

  .related_video_title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
    @media screen and (min-width: 320px) and (max-width: 1800px) {
        margin-top: 10px;
    }
  }
  
  .closeButton {
    position: absolute !important;
    top: 8px; // Adjust these values based on your layout
    right: 8px;
    color: #212121; // Or whatever color fits your design
    cursor: pointer;

    &:hover {
        color: #ff0000; // Darken the color on hover
        
    }
  }

.ImageDescription {
    display: flex;
    flex-direction: row;
    .videoImage {
        width: 50%;
    }

    @media screen and (min-width: 320px) and (max-width: 1800px) {
        flex-direction: column;
        padding: 10px 12px 10px 12px;
    }
}

.related_video {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (min-width: 320px) and (max-width: 1800px) {
        flex-direction: column;
    }
}

.related_video_player {
    border-radius: 10px;
    @media screen and (min-width: 320px) and (max-width: 1800px) {
        width: 100%;
    }
}

.related_video_info {
    margin-left: 15px;
    @media screen and (min-width: 320px) and (max-width: 1800px) {
        margin-left: 0;
    }

}

.related_videos {
    padding: 16px 24px;
    @media screen and (min-width: 320px) and (max-width: 1800px) {
        padding: 0 12px 10px 12px;
    }
}
